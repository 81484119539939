import {
    DataGridModelProps,
    createModelMeta,
    useCacheFetch,
    isLazyCacheNeedToRefresh,
    toPopupColumns, AddDefaultSort,
} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import ProgressLabel from "@/core-ui/ProgressLabel.vue";
import MemoryLabel from "@/core-ui/MemoryLabel.vue";
import { inPercentagesTo, numeric, percentages } from "@/core-ui/helpers/data-formats";
import nodesService from "@/services/nodes-service";
import { ClusterUUIDKey, useClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import getMemory from "@/core-ui/helpers/memory-formats";
import HoverLinks from "@/core-ui/HoverLinks.vue";
import { HoverLinksProps } from "@/core-ui/types/hover-link";
import {computed} from "vue";

export function useNodeLazyCache() {
    const cid = useClusterUUIDKey({ key: "" });
    const expTimestamp = 60000;

    const cache = useCacheFetch<Record<string, Node>>({
        get key() {
            return cid.clusterUUID;
        },
        fetch: () =>
            nodesService.getList(cid.clusterUUID).then((d) => Object.fromEntries(d.map((n: Node) => [n.node, n]))),
        defaultData: {},
    });
    return {
        get state() {
            return cache.state;
        },
        get(id: string | number) {
            if (cache.state == "idle") {
                cache.fetch();
            } else {
                if (isLazyCacheNeedToRefresh(cache, expTimestamp)) {
                    cache.refresh();
                }
                return cache.data[id];
            }
        },
    };
}

export function createCompLinkForNode(nodeCache: any) {
    return {
        component: HoverLinks,
        mapProps: (val: any): HoverLinksProps => ({
            items: (val ?? "").split(", ").map((v: string) => ({
                text: v,
                dataId: v,
                link: v && v != "-" ? `/nodes?query=node: ${v}` : undefined,
            })),
            dataCache: nodeCache,
            dataMeta: toPopupColumns(columns.filter((c) => !c.filter || c.filter())),
        }),
    };
}

export const columns: DataGridColumn[] = [
    {
        key: "node-pool",
        label: "Node Pool",
        dataKey: "nodePool",
        searchable: true,
        sortable: true,
        display: {
            popup: {},
            table: {
                hidden: true,
                width: 150,
            },
        },
    },
    {
        key: "id",
        label: "ID",
        dataKey: "id",
        primary: true,
        searchable: true,
        sortable: false,
        display: null,
    },
    {
        key: "node",
        label: "Node",
        dataKey: "node",
        searchable: true,
        sortable: true,
        display: {
            popup: { type: "title" },
            table: {
                width: 280,
            },
        },
    },
    {
        key: "status",
        label: "Status",
        dataKey: "nodeStatus",
        searchable: true,
        sortable: true,
        dataTransform: (d) => (d ? d : "Unknown"),
        display: {
            popup: {},
            table: {
                width: 200,
            },
        },
    },
    {
        key: "gpu-type",
        label: "GPU Type",
        dataKey: "gpuType",
        searchable: true,
        sortable: true,
        display: {
            popup: {
                hiddenEmpty: true,
            },
            table: {
                width: 150,
            },
        },
    },
    {
        key: "utilization",
        label: "GPU Utilization",
        dataKey: "utilization",
        searchable: true,
        sortable: true,
        dataTransform: numeric,
        display: {
            component: ProgressLabel,
            table: {
                width: 160,
            },
        },
    },
    // {
    // 	key: 'gpu-type',
    // 	label: 'GPU Type',
    //     dataKey: 'gpu_type',
    //     searchable: true,
    //     sortable: true,
    //     display: {
    //      table : {
    //         hidden: true,
    //         width: 107
    //     }
    // },
    {
        key: "gpu",
        label: "GPUs",
        dataKey: "totalGpus",
        searchable: true,
        sortable: true,
        dataTransform: numeric,
        display: {
            popup: {},
            table: {
                width: 107,
                alignedRight: true,
            },
        },
    },
    {
        key: "allocated-gpu",
        label: "Allocated GPUs",
        dataKey: "allocatedGpus",
        searchable: true,
        sortable: true,
        dataTransform: inPercentagesTo("totalGpus", numeric),
        display: {
            table: {
                width: 146,
                alignedRight: true,
            },
        },
    },
    {
        key: "cpu",
        label: "CPUs",
        dataKey: "totalCpus",
        searchable: true,
        sortable: true,
        dataTransform: numeric,
        display: {
            popup: {},
            table: {
                hidden: true,
                width: 107,
                alignedRight: true,
            },
        },
    },
    {
        key: "cpu-utilization",
        label: "CPU Utilization",
        dataKey: "usedCpus",
        searchable: true,
        sortable: true,
        dataTransform: numeric,
        display: {
            component: ProgressLabel,
            table: {
                width: 140,
            },
        },
    },
    {
        key: "allocated-cpu",
        label: "Allocated CPUs",
        dataKey: "allocatedCpus",
        searchable: true,
        sortable: true,
        dataTransform: inPercentagesTo("totalCpus", numeric),
        display: {
            table: {
                hidden: true,
                width: 160,
                alignedRight: true,
            },
        },
    },

    {
        key: "gpu-memory",
        label: "GPU Memory",
        dataKey: "totalGpuMemory",
        searchable: true,
        sortable: true,
        dataTransform: numeric,
        display: {
            component: MemoryLabel,
            popup: {},
            table: {
                hidden: true,
                width: 160,
                alignedRight: true,
            },
        },
    },
    {
        key: "used-gpu-memory",
        label: "Used GPU Memory",
        dataKey: "usedGpuMemory",
        searchable: true,
        sortable: true,
        dataTransform: inPercentagesTo("totalGpuMemory", getMemory),
        display: {
            table: {
                width: 175,
                alignedRight: true,
            },
        },
    },
    {
        key: "cpu-memory",
        label: "CPU Memory",
        dataKey: "totalCpuMemory",
        searchable: true,
        sortable: true,
        dataTransform: numeric,
        display: {
            component: MemoryLabel,
            popup: {},
            table: {
                hidden: true,
                width: 160,
                alignedRight: true,
            },
        },
    },

    {
        key: "used-cpu-memory",
        label: "Used CPU Memory",
        dataKey: "usedCpuMemory",
        searchable: true,
        sortable: true,
        dataTransform: inPercentagesTo("totalCpuMemory", getMemory),
        display: {
            table: {
                hidden: true,
                width: 175,
                alignedRight: true,
            },
        },
    },
    {
        key: "swap-cpu-memory",
        label: "Used Swap CPU Memory",
        dataKey: "swapCpuMemory",
        searchable: true,
        sortable: true,
        dataTransform: inPercentagesTo("totalCpuMemory", getMemory),
        display: {
            table: {
                hidden: true,
                width: 200,
                alignedRight: true,
            },
        },
    },
    {
        key: "allocated-cpu-memory",
        label: "Allocated CPU Memory",
        dataKey: "allocatedMemory",
        searchable: true,
        sortable: true,
        dataTransform: inPercentagesTo("totalCpuMemory", getMemory),
        display: {
            table: {
                hidden: true,
                width: 200,
                alignedRight: true,
            },
        },
    },
];

export type ModalType = Node;
const type = "node";
export const meta = createModelMeta<ModalType>({
    icon: `raicon-${type}`,
    type,
    display: (item) => item.node,
});

export const createModelProps = (props: ClusterUUIDKey): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => {
        return AddDefaultSort(columns, type)
    });
    return ({
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        columns: computedColumns.value,
        syncServer: false,
        fetchInterval: 10000,
        fetch: async (filter) => {
            const data = await nodesService.getList(props.clusterUUID);
            return {data};
        },
    })
};

export interface Node {
    id: string;
    node: any;
    utilization: any;
    gpu_type: any;
    totalGpus: any;
    totalMigDevices: any;
    usedGpus: any;
    totalCpus: any;
    usedCpus: any;
    totalGpuMemory: any;
    usedGpuMemory: any;
    totalCpuMemory: any;
    usedCpuMemory: any;
    swapCpuMemory: any;
}
