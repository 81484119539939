
import DataGrid from "@/core-ui/data-grid/components/DataGrid.vue";
import { useDataGridModel } from "@/core-ui/data-grid/compositions";
import { createModelProps } from "@/models/events.model";
import { defineComponent } from "vue";
import { useClusterUUIDKey } from "@/compositions/ClusterUUIDKey";

export default defineComponent({
    components: { DataGrid },
    props: {
        podGroupId: String,
        clusterUuid: String,
    },
    setup(props: any) {
        const clusterKey = useClusterUUIDKey({ key: "job-events" });

        const model = useDataGridModel(
            createModelProps({
                get jobId() {
                    return props.podGroupId;
                },
                get clusterUuid() {
                    return clusterKey.clusterUUID;
                },
                get deploymentId() {
                    return "";
                },
            }),
        );

        return {
            model,
        };
    },
});
