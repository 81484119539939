import prometheusService from "./prometheus-service";
import * as prom from "@/core-ui/helpers/prometheus";

class NodesService {
    constructor() {
        this.getList = this.getList.bind(this);
    }

    async getList(clusterId: string) {
        const clusterFilter = `{clusterId="${clusterId}"}`;
        const clusterFilterAddition = `, clusterId="${clusterId}"`;

        const promQueries: { [key: string]: string } = {
            totalGpus: `(count(runai_gpus_is_running_with_pod2${clusterFilter}) by (node))`,
            nodeStatus: `kube_node_status_condition{status="true" ${clusterFilterAddition}}==1`,
            totalMigDevices: `label_replace(runai_mig_mode_gpu_count${clusterFilter}, "node", "$1", "node_name", "(.*)")`,
            allocatedMigGpus: `label_replace(runai_mig_mode_allocated_gpus${clusterFilter}, "node", "$1", "node_name", "(.*)")`,
            totalMigGpuMemory: `label_replace(runai_mig_mode_gpu_total_memory${clusterFilter}, "node", "$1", "node_name", "(.*)") * 1024 * 1024`,
            allocatedGpus: `((sum(runai_gpus_is_running_with_pod2${clusterFilter}) by (node))) + (sum(runai_used_shared_gpu_per_node${clusterFilter}) by (node))`,
            usedGpuMemory: `(sum(runai_node_gpu_used_memory${clusterFilter} * 1024 * 1024) by (node))`,
            totalGpuMemory: `(sum(runai_node_gpu_total_memory${clusterFilter} * 1024 * 1024) by (node))`,
            totalCpuMemory: `(sum (kube_node_status_capacity{resource="memory"${clusterFilterAddition}}) by (node))`,
            usedCpuMemory: `runai_node_memory_used_bytes${clusterFilter}`,
            swapCpuMemory: `(sum (runai_pod_group_swap_memory_used_bytes${clusterFilter}) by (node))`,
            totalCpus: `(sum (kube_node_status_capacity{resource="cpu"${clusterFilterAddition}}) by (node))`,
            usedCpus: `avg(runai_node_cpu_utilization${clusterFilter}) by(node) * 100`,
            allocatedCpus: `runai_node_cpu_requested${clusterFilter}`,
            allocatedMemory: `runai_node_requested_memory_bytes${clusterFilter}`,
            utilization: `((sum(runai_node_gpu_utilization${clusterFilter}) by (node)) / on (node) (count(runai_node_gpu_utilization) by (node)))`,
            general: `sum(kube_node_status_condition${clusterFilter}) by (node, namespace)`,
            gpuType: `sum by (modelName, node) ((label_replace(dcgm_gpu_utilization${clusterFilter},"pod_ip", "$1", "instance", "(.*):(.*)")) * on(pod_ip) group_left(node) kube_pod_info{created_by_name=~".*dcgm-exporter"${clusterFilterAddition}})`,
            ready: `sum(kube_node_status_condition{condition="Ready",status="true"${clusterFilterAddition}}) by (node)`,
            nodePool: `runai_node_nodepool${clusterFilter}==1`,
        };

        const extractor = {
            gpuType: prom.extractLabels({ modelName: "gpuType" }),
            nodeStatus: prom.extractLabels({ condition: "nodeStatus" }),
            nodePool: prom.extractLabels({ nodepool: "nodePool" }),
        };
        return prometheusService
            .getFilteredResultMultipleQueries(promQueries, "node", undefined, extractor, 2)
            .then((list) => list.filter((row) => row.ready === "1").map(mapNode));
    }

    getCpuUtilRange(node: string, start?: Date | number, end?: Date | number, step = 100) {
        return prometheusService.rangeQuery({
            query: `sum(runai_node_cpu_utilization{node="${node}"}) * 100`,
            start,
            end,
            step,
        });
    }

    getGPUMemoryUtilRange(node: string, start?: Date | number, end?: Date | number, step = 100) {
        return prometheusService.rangeQuery({
            query: `(sum(runai_node_gpu_used_memory{node="${node}"} * 1024 * 1024) by (node))`,
            start,
            end,
            step,
        });
    }

    getMemoryUtilRange(node: string, start?: Date | number, end?: Date | number, step = 100) {
        return prometheusService.rangeQuery({
            query: `sum(runai_node_memory_used_bytes{node="${node}"}) by (node)`,
            start,
            end,
            step,
        });
    }

    getGPUsUtilRange(node: string, start?: Date | number, end?: Date | number, step = 100) {
        return prometheusService.rangeQuery({
            query: `(avg(runai_node_gpu_utilization{node="${node}"}) or vector(0))`,
            start,
            end,
            step,
        });
    }

    getGpuUtilizationRange(node: string, start?: Date | number, end?: Date | number, step = 100) {
        const queries = [
            `(avg(runai_node_gpu_utilization{node="${node}"}) by (gpu) or vector(0))`,
            `(avg(runai_node_gpu_utilization{node="${node}"}) or vector(0))`,
        ];
        return prometheusService.getMultipleRangeQueries(queries, start, end, step).then((results: any) => {
            return results.reduce(
                (flat: any, data: any) => {
                    let arr = data.result || [];
                    if (arr && data.query === queries[0]) {
                        arr = arr
                            .filter((series: any) => series.metric.gpu != undefined)
                            .reduce((o: any, s: any) => {
                                o[`gpu ${s.metric.gpu}`] = s.values;
                                return o;
                            }, flat);
                    } else {
                        flat["average"] = arr[0]?.values || [];
                    }
                    return flat;
                },
                { average: [] },
            );
        });
    }
}

const NUMERIC_FIELDS = [
    "totalGpuMemory",
    "usedGpuMemory",
    "totalGpus",
    "totalMigDevices",
    "allocatedMigGpus",
    "totalMigGpuMemory",
    "totalCpus",
    "usedCpus",
    "allocatedGpus",
    "utilization",
];

function preCalculation(nodeValues: any) {
    if (nodeValues["totalMigDevices"]) {
        nodeValues["totalGpus"] = nodeValues["totalMigDevices"];
        nodeValues["utilization"] = "-1";
        nodeValues["allocatedGpus"] = nodeValues["allocatedMigGpus"];
        nodeValues["usedGpuMemory"] = "-1";
        nodeValues["totalGpuMemory"] = nodeValues["totalMigGpuMemory"];
    }
    return nodeValues;
}

// TODO: make it a more general solution maybe add type for each column
function mapNode(node: any) {
    node = preCalculation(node);
    const mapped = {
        ...node,
    };
    for (const key of NUMERIC_FIELDS) {
        mapped[key] = parseFloat(mapped[key]) || 0;
    }

    return mapped;
}

const nodesService = new NodesService();
export default nodesService;
