
import RaTabs from "../layouts/RaTabs.vue";
import DetailsPanel from "@/core-ui/data-grid/components/DetailsPanel.vue";
import { Tab } from "../layouts/types/tab";
import StatusLabel from "@/core-ui/StatusLabel.vue";
import GeneralTab from "./GeneralTab.vue";
import LogsTab from "./LogsTab.vue";

import { DataGridColumn } from "@/core-ui/types/column";
import GpusTab from "../gpus/GpusTab.vue";
import PodsTab from "../pods/PodsTab.vue";
import GraphsTab from "./GraphsTab.vue";
import { LabelType } from "@/types/label-type";
import { defineComponent, PropType, ref, watch } from "vue";
import authStore from "@/stores/authStore";
import K8sEventsTab from "../k8s-events/k8sJobEventsTab.vue";
import { routerService } from "@/core-ui/data-grid/services";
import { TABS, urlQueryJobTabName } from "./utils";

export default defineComponent({
    components: {
        GraphsTab,
        PodsTab,
        GpusTab,
        GeneralTab,
        LogsTab,
        StatusLabel,
        DetailsPanel,
        RaTabs,
        K8sEventsTab,
    },
    emits: ["close"],
    props: {
        job: Object,
        columns: Array as PropType<Array<DataGridColumn>>,
        isHistory: { type: Boolean, default: false },
    },
    methods: {
        webkitTransitionEndCallback() {
            if (this.$el.clientWidth) {
                this.transitionEnded = true;
            }
        },
    },
    setup(props) {
        const currentIndexTab = ref(0);
        const transitionEnded = ref(false);

        const self = {
            get jobCopy() {
                const re = /, /gi;
                const jobCopy = JSON.parse(JSON.stringify(props.job));
                if (jobCopy.jobUrl) jobCopy.jobUrl = jobCopy.jobUrl.replace(re, "\n");
                return jobCopy;
            },
            currentIndexTab,
            transitionEnded,

            get currentTab(): Tab {
                const title = routerService.getStr(urlQueryJobTabName, TABS.GENERAL.title);
                const tab = self.tabs.find((t) => t.title == title);
                return tab ? tab : TABS.GENERAL;
            },
            set currentTab(tab: Tab) {
                routerService.setStr(urlQueryJobTabName, tab.title);
            },

            get isGraphTab() {
                return true; //!!props.job!.podUUID;
            },

            get tabs(): Array<Tab> {
                const job = props.job;
                const tabs: any[] = [TABS.GENERAL, TABS.PODS, TABS.EVENTS];
                if (job && job.nodeId && job.status === LabelType.RUNNING) {
                    tabs.push(TABS.GPUS);
                }
                if (!props.isHistory && self.isGraphTab) {
                    tabs.push(TABS.GRAPHS);
                }
                if (!props.isHistory && authStore.isResearcher) {
                    tabs.push({
                        ...TABS.LOGS,
                    });
                }

                return tabs;
            },

            get hasJob() {
                return !!props.job;
            },

            get allColumns() {
                return props.columns!.filter((col: any) => col.dataKey !== "training_lib" && col.dataKey !== "jobName");
            },
        };

        watch(
            () => props.job,
            () => {
                if (!props.job) {
                    transitionEnded.value = false;
                }
            },
        );

        return self;
    },

    mounted() {
        this.$parent!.$el.addEventListener("webkitTransitionEnd", this.webkitTransitionEndCallback);
    },

    unmounted() {
        this.$parent!.$el.removeEventListener("webkitTransitionEnd", this.webkitTransitionEndCallback);
    },
});
