import { Job } from "../models/job.model";
import { clusterApi } from "./apiClient";

export default {
    async get(project = "", type = ""): Promise<Job[]> {
        return clusterApi.get(`/api/v1/jobs/${project}?jobTypeFilter=${type}`).then((res) => mapJobs(res.data.data));
    },
    async delete(jobs: Job[]) {
        return clusterApi.del("/api/v1/jobs", {
            data: jobs.map(({ name, project }) => ({ name, project })),
        });
    },
    async suspend(jobs: Job[]) {
        return clusterApi.post(
            "/api/v1/jobs/suspension/suspend",
            jobs.map(({ name, project }) => ({ name, project })),
        );
    },
    async resume(jobs: Job[]) {
        return clusterApi.post(
            "/api/v1/jobs/suspension/resume",
            jobs.map(({ name, project }) => ({ name, project })),
        );
    },
};

function mapJobs(data: Job[]): Job[] {
    data.forEach((p) => {
        p.nodes = p.nodes && p.nodes.length ? (p.nodes! as string[]).join(", ") : "-";
        p.id = `${p.project}-${p.name}`;
    });

    return data;
}
