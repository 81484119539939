import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["content"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusLabel = _resolveComponent("StatusLabel")!
  const _component_RaTabs = _resolveComponent("RaTabs")!
  const _component_GeneralTab = _resolveComponent("GeneralTab")!
  const _component_PodsTab = _resolveComponent("PodsTab")!
  const _component_K8sEventsTab = _resolveComponent("K8sEventsTab")!
  const _component_GpusTab = _resolveComponent("GpusTab")!
  const _component_GraphsTab = _resolveComponent("GraphsTab")!
  const _component_LogsTab = _resolveComponent("LogsTab")!
  const _component_DetailsPanel = _resolveComponent("DetailsPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_DetailsPanel, {
    open: _ctx.hasJob,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
    onTransitionEnded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.transitionEnded = true))
  }, {
    default: _withCtx(() => [
      (_ctx.job)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.row, _ctx.$style.header])
            }, [
              _withDirectives((_openBlock(), _createElementBlock("label", {
                content: _ctx.job.jobName,
                class: _normalizeClass(_ctx.$style.title)
              }, [
                _createTextVNode(_toDisplayString(_ctx.job.jobName), 1)
              ], 10, _hoisted_1)), [
                [_directive_tippy]
              ]),
              (!_ctx.isHistory)
                ? (_openBlock(), _createBlock(_component_StatusLabel, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.status),
                    value: _ctx.job.training_lib
                  }, null, 8, ["class", "value"]))
                : _createCommentVNode("", true)
            ], 2),
            _createVNode(_component_RaTabs, {
              tabs: _ctx.tabs,
              "current-tab": _ctx.currentTab,
              onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTab = $event))
            }, null, 8, ["tabs", "current-tab"]),
            (_ctx.currentTab.title === 'General')
              ? (_openBlock(), _createBlock(_component_GeneralTab, {
                  key: 0,
                  model: _ctx.jobCopy,
                  columns: _ctx.allColumns
                }, null, 8, ["model", "columns"]))
              : (_ctx.currentTab.title === 'Pods')
                ? (_openBlock(), _createBlock(_component_PodsTab, {
                    key: 1,
                    "node-id": _ctx.job.nodeId,
                    "pod-group-id": _ctx.job.podGroupId
                  }, null, 8, ["node-id", "pod-group-id"]))
                : (_ctx.currentTab.title === 'Status History')
                  ? (_openBlock(), _createBlock(_component_K8sEventsTab, {
                      key: 2,
                      "pod-group-id": _ctx.job.podGroupId
                    }, null, 8, ["pod-group-id"]))
                  : (_ctx.currentTab.title === 'GPUs')
                    ? (_openBlock(), _createBlock(_component_GpusTab, {
                        key: 3,
                        "node-id": _ctx.job.nodeId,
                        "pod-group-id": _ctx.job.podGroupId
                      }, null, 8, ["node-id", "pod-group-id"]))
                    : (_ctx.currentTab.title === 'Graphs' && _ctx.isGraphTab && _ctx.transitionEnded)
                      ? (_openBlock(), _createBlock(_component_GraphsTab, {
                          key: 4,
                          job: _ctx.job
                        }, null, 8, ["job"]))
                      : (_ctx.currentTab.title === 'Logs')
                        ? (_openBlock(), _createBlock(_component_LogsTab, {
                            key: 5,
                            job: _ctx.job
                          }, null, 8, ["job"]))
                        : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open"]))
}