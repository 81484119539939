
// components
import StatusLabel from "@/core-ui/StatusLabel.vue";
import Popper from "@/core-ui/Popper.vue";
import Loading from "@/core-ui/Loading.vue";
import TextButton from "@/core-ui/TextButton.vue";

import { defineComponent, PropType } from "@vue/runtime-core";
import { isLoading as isLoadingFunc, useCacheFetch } from "@/core-ui/data-grid/compositions";
import { Job } from "@/types/job";
import eventService from "@/services/events-service";
import { routerService } from "@/core-ui/data-grid/services";
import { TABS, urlQueryJobTabName } from "./utils";

export default defineComponent({
    components: { StatusLabel, Popper, Loading, TextButton },
    props: {
        row: Object as PropType<Job>,
        value: {},
        ctx: Object,
    },
    setup(props) {
        const expieredInterval = 30000;

        let lastFetch = 0;

        const cache = useCacheFetch({
            get key() {
                return props?.row?.podGroupId || "id";
            },
            fetch: async () => {
                lastFetch = Date.now();
                const events = await eventService.getByJobId(props.row!.clusterId, props.row!.podGroupId);
                if (events[0]) {
                    return events[0].message;
                } else {
                    return null;
                }
            },
        });
        const self = {
            get isData() {
                return !!cache.data;
            },

            open() {
                const id = props.row?.podGroupId;
                if (!id || !props.ctx?.selector) {
                    return;
                }
                if (!props.ctx?.selector?.isSelected(props.row!.podGroupId)) {
                    props.ctx!.selector.toggle(id);
                }
                self.markEvents();
            },

            markEvents() {
                routerService.setStr(urlQueryJobTabName, TABS.EVENTS.title);
            },

            get isLoading() {
                return isLoadingFunc(cache.state);
            },

            get data() {
                return cache.data;
            },

            onPopped() {
                if (cache.state == "idle" || lastFetch + expieredInterval < Date.now()) {
                    cache.fetch();
                }
            },
        };
        return self;
    },
});
