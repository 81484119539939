export type Range = [number, number];

export const urlQueryJobTabName = "job_details_tab";

export const TABS = {
    GENERAL: { title: "General" },
    PODS: { title: "Pods" },
    GPUS: { title: "GPUs" },
    GRAPHS: { title: "Graphs" },
    LOGS: { title: "Logs" },
    EVENTS: { title: "Status History" },
};
